import enUS from './languages/en-US.json';
import ptBR from './languages/pt-BR.json';

export type Language = 'en-US' | 'pt-BR';

export const defaultNS = 'common';

export const resources = {
  'en-US': enUS,
  'pt-BR': ptBR,
} as const;
