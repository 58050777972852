import { PropsWithChildren } from 'react';
import { QueryClientProvider as Provider, QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

export function QueryClientProvider(props: PropsWithChildren<object>) {
  return <Provider client={queryClient}>{props.children}</Provider>;
}
