import { AppHeaderConfigPayload } from 'admin-portal-shared-services';
import { t } from 'i18next';
import { Modules } from 'routes/Definition';

export const getAppHeaderConfig: (pageName: Modules) => Promise<AppHeaderConfigPayload> = async (
  pageName
) => {
  const { homePath, segmentationPath } = await import('routes/Definition');

  switch (pageName) {
    case 'HOME':
      return {
        pageTitle: ' ',
        breadcrumbConfig: undefined,
      };
    case 'SEGMENTATION':
      return {
        pageTitle: t('segmentation:moduleName'),
        breadcrumbConfig: {
          homePath,
          items: getItemsSegmentation(segmentationPath),
        },
      };
    case 'INFORMATIONCENTER':
      return {};
    case 'CUSTOMERLIST':
      return {};
    case 'CHAINMANAGEMENT':
      return {};
  }
};

const getItemsSegmentation = (path: string) => [
  {
    isCurrentPage: true,
    path,
    label: t('segmentation:moduleName'),
  },
];
