import { useAuthenticationService } from 'admin-portal-shared-services';
import axios, { AxiosResponse } from 'axios';
import { useEnvironment } from 'hooks/useEnvironment/useEnvironment';
import { v4 } from 'uuid';
import { IGet } from './api.types';

export const useApi = () => {
  const { baseUrl: baseURL } = useEnvironment();
  const auth = useAuthenticationService();
  const token = auth.getAuthHeader();

  const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: {
      Authorization: token,
      requestTraceId: `information-center-${v4()}`,
    },
  });

  const api = {
    get: function get<T>(props: IGet): Promise<AxiosResponse<T>> {
      return axiosInstance.get<T>(props.url, props.config);
    },
  };

  return api;
};
