// import { AppHeader } from '@components';
import { AppHeader } from '@components';
import { GlobalProvider } from '@providers';
import { Router } from './routes';

export default function App() {
  return (
    <GlobalProvider>
      <AppHeader />
      <Router />
    </GlobalProvider>
  );
}
