import { useAppHeader, usePermissionService } from 'admin-portal-shared-services';
import { useApi } from 'apis/api2';
import { useQuery } from 'react-query';

import { TSidebarResponse } from './SidebarService.types';

export const useSidebarService = () => {
  const [appHeaderConfig] = useAppHeader();
  const { selectedCountry } = appHeaderConfig;
  const api = useApi();
  const permissionService = usePermissionService();

  const { data = [], isSuccess } = useQuery(['sidebar', selectedCountry], async () => {
    const response = await api.get<TSidebarResponse>({
      url: 'api/modern-trade-management/mfe',
      config: {
        headers: {
          requestTraceId: 'sidebar',
          country: selectedCountry,
        },
      },
    });
    /* istanbul ignore next */
    return response.data.content.filter((res) =>
      res.id == 'common'
        ? permissionService.hasScopesToApp(`${res.permission}`)
        : permissionService.hasOneOfScopes(`${res.permission}`)
    );
  });

  return { data, isSuccess };
};
