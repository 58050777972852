import { getAppHeaderConfig } from '@constants';
import { Grid } from '@hexa-ui/components';
import { useAppHeader } from 'admin-portal-shared-services';
import { useDynamicSidebar } from 'hooks/useDynamicSidebar';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styles from './PageWrapper.styles';
import { PageWrapperProps } from './PageWrapper.types';

export const PageWrapper = ({
  children,
  showBreadcrumb = true,
  pageName = 'HOME',
  ...props
}: PageWrapperProps) => {
  const { t } = useTranslation([
    'common',
    'segmentation',
    'informationCenter',
    'customerList',
    'chainManagement',
  ]);
  const [, setAppHeaderConfig] = useAppHeader();

  useDynamicSidebar();

  useEffect(() => {
    async function setBreadcrumb() {
      const headerConfig = await getAppHeaderConfig(pageName);
      setAppHeaderConfig(headerConfig);
    }

    if (showBreadcrumb) {
      /* istanbul ignore next */
      setBreadcrumb().catch((error) => console.error(error));
    }
  }, [pageName, setAppHeaderConfig, showBreadcrumb]);

  return (
    <Grid.Container sidebar type="fluid" {...props}>
      <Styles.Content>{children}</Styles.Content>
    </Grid.Container>
  );
};

