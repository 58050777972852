import { createContext, PropsWithChildren, useMemo } from 'react';

export type EnvConfig = {
  SEGMENT_KEY: string;
  baseUrl: string;
};

export const defaultInitialValue: EnvConfig = {
  SEGMENT_KEY: '',
  baseUrl: '',
};

export const EnvironmentContext = createContext<EnvConfig>(defaultInitialValue);

export function EnvironmentProvider(props: PropsWithChildren<EnvConfig>) {
  const baseUrl = window.origin;
  const { SEGMENT_KEY } = props;

  const value = useMemo(
    () => ({
      baseUrl,
      SEGMENT_KEY,
    }),
    [baseUrl, SEGMENT_KEY]
  );

  return <EnvironmentContext.Provider value={value}>{props.children}</EnvironmentContext.Provider>;
}
