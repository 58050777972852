import { PageLoader } from '@components';
import { ChartTree, Home, List3, Store, UserProfile } from '@hexa-ui/icons';
import { Namespaces } from 'i18next';
import { ElementType, lazy, LazyExoticComponent, ReactElement, Suspense } from 'react';

export type Modules =
  | 'HOME'
  | 'SEGMENTATION'
  | 'CHAINMANAGEMENT'
  | 'INFORMATIONCENTER'
  | 'CUSTOMERLIST';

type Route = {
  path: string;
  Element?: LazyExoticComponent<() => ReactElement>;
  children?: Route[];
};

type ModulesSideBar = Modules;

type RoutesProps = Record<Modules, Route>;

type SidebarProps = Record<ModulesSideBar, { id: Namespaces; path: string; icon: ElementType }>;

export const SegmentationPage = lazy(() => import('views/Segmentation'));

export const LINK_ADMIN_ROUTE = 'link-admin';
export const BASE_NAME = 'bees-poc-segment';

export const homePath = `/${LINK_ADMIN_ROUTE}`;
export const segmentationPath = `/${BASE_NAME}`;

export const informationCenterPath = `/bees-information-center`;
export const customerListPath = '/customer-list';
export const chainManagementPath = '/bees-chain-management';

export const Routes: RoutesProps = {
  HOME: { path: homePath },
  SEGMENTATION: {
    path: segmentationPath,
    Element: SegmentationPage,
  },
  INFORMATIONCENTER: { path: informationCenterPath },
  CUSTOMERLIST: { path: customerListPath },
  CHAINMANAGEMENT: { path: chainManagementPath },
};

export const Sidebar: SidebarProps = {
  HOME: {
    id: 'common',
    path: homePath,
    icon: () => <Home />,
  },
  INFORMATIONCENTER: {
    id: 'informationCenter',
    path: informationCenterPath,
    icon: () => <UserProfile />,
  },
  CUSTOMERLIST: {
    id: 'customerList',
    path: `${customerListPath}`,
    icon: () => <ChartTree />,
  },
  CHAINMANAGEMENT: {
    id: 'chainManagement',
    path: `${chainManagementPath}`,
    icon: () => <Store />,
  },
  SEGMENTATION: {
    id: 'segmentation',
    path: segmentationPath,
    icon: () => <List3 />,
  },
};

export const sidebar = [
  Sidebar.HOME,
  Sidebar.INFORMATIONCENTER,
  Sidebar.CUSTOMERLIST,
  Sidebar.CHAINMANAGEMENT,
  Sidebar.SEGMENTATION,
];
export const modules = [Routes.HOME, Routes.SEGMENTATION];

type Sidebar = 'HOME' | 'SEGMENTATION';
type Routes = Sidebar;

export const routesConfig = modules.map(({ path, Element }) => ({
  path,
  element: <Suspense fallback={<PageLoader />}>{Element ? <Element /> : <></>}</Suspense>,
}));
