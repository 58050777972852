import { Toast } from '@hexa-ui/components';
import { I18nProvider, QueryClientProvider, ThemeProvider } from '@providers';
import { PropsWithChildren } from 'react';

export function GlobalProvider(props: PropsWithChildren<object>) {
  return (
    <ThemeProvider>
      <I18nProvider>
        <QueryClientProvider>
          <Toast.Provider duration={3000} swipeDirection="right">
            {props.children}
          </Toast.Provider>
        </QueryClientProvider>
      </I18nProvider>
    </ThemeProvider>
  );
}
